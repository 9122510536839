export default {
  backup: 'Kopia zapasowa',
  downloadTheProjectBackupFile: 'Pobierz plik kopii zapasowej projektu (w formacie json)',
  restoreProject: 'Przywrócić...',
  incorrectUsrPwd: 'Nieprawidłowa nazwa użytkownika lub hasło.',
  askDeleteUser: 'Czy na pewno chcesz usunąć tego użytkownika?',
  askDeleteTemp: 'Czy na pewno chcesz usunąć ten szablon?',
  askDeleteEnv: 'Czy na pewno chcesz usunąć to środowisko?',
  askDeleteInv: 'Czy na pewno chcesz usunąć tę ewidencję?',
  askDeleteKey: 'Czy na pewno chcesz usunąć ten klucz?',
  askDeleteRepo: 'Czy na pewno chcesz usunąć to repozytorium?',
  askDeleteProj: 'Czy na pewno chcesz usunąć ten projekt?',
  askDeleteTMem: 'Czy na pewno chcesz usunąć tego uczestnika zespołu?',
  edit: 'Modyfikuj',
  nnew: 'Utwórz',
  keyFormSshKey: 'Klucz SSH',
  keyFormLoginPassword: 'Login with password',
  keyFormNone: 'None',
  incorrectUrl: 'Nieprawidłowy URL',
  username: 'Nazwa użytkownika',
  username_required: 'Nazwa użytkownika jest wymagana.',
  dashboard: 'Pulpit',
  history: 'Historia',
  activity: 'Czynność',
  settings: 'Ustawienia',
  signIn: 'Zaloguj się',
  password: 'Hasło',
  changePassword: 'Zmień hasło',
  editUser: 'Edytuj użytkownika',
  newProject: 'Nowy projekt',
  close: 'Zamknij',
  newProject2: 'Nowy projekt...',
  demoMode: 'TRYB DEMO',
  task: 'Zadanie #{expr}',
  youCanRunAnyTasks: 'Możesz uruchamiać każde zadanie',
  youHaveReadonlyAccess: 'Masz uprawnienia jedynie do odczytu',
  taskTemplates: 'Szablony zadań',
  inventory: 'Ewidencja',
  environment: 'Środowisko',
  keyStore: 'Magazyn kluczy',
  repositories: 'Repozytoria',
  darkMode: 'Wersja ciemna',
  team: 'Zespół',
  users: 'Użytkownicy',
  editAccount: 'Modyfikuj konto',
  signOut: 'Wyjdź',
  error: 'Błąd',
  refreshPage: 'Odśwież stronę',
  relogin: 'Zaloguj ponownie',
  howToFixSigninIssues: 'Rozwiązywanie problemów z logowaniem',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Najpierw wejdź na serwer na którym uruchomiony jest Semaphore',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Wykonaj poniższe polecenie aby zobaczyć użytkowników:',
  semaphoreUserList: 'Lista użytkowników Semaphore',
  youCanChangePasswordOfExistingUser: 'Możesz zmienić hasło istniejącego użytkownika:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'lub stworzyć nowego administratora:',
  close2: 'Zamknij',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: 'Nie masz konta, lub masz kłopoty z logowaniem?',
  password2: 'Hasło',
  cancel: 'Anuluj',
  noViews: 'Brak widoków',
  addView: 'Dodaj widok',
  editEnvironment: 'Edytuj środowisko',
  deleteEnvironment: 'Usuń Środowisko',
  environment2: 'Środowisko',
  newEnvironment: 'Nowe środowisko',
  environmentName: 'Nazwa środowiska',
  extraVariables: 'Dodatkowe zmienne',
  enterExtraVariablesJson: 'Wprowadź dodatkowe zmienne w formacie JSON...',
  environmentVariables: 'Zmienne środowiskowe',
  enterEnvJson: 'Wprowadź środowisko JSON...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Środowisko i dodatkowe zmienne muszą być w poprawnym formacie JSON np.',
  dashboard2: 'Pulpit',
  ansibleSemaphore: 'Ansible Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Przepraszamy, ale <%= htmlWebpackPlugin.options.title %> nie działa poprawnie bez Javascript-u. Włącz JS i spróbuj ponownie.',
  deleteInventory: 'Usuń ewidencję',
  newInventory: 'Nowa ewidencja',
  name: 'Nazwa',
  userCredentials: 'Dane użytkownika',
  sudoCredentialsOptional: 'Dane dla Sudo (Optional)',
  type: 'Typ',
  pathToInventoryFile: 'Ścieżka do pliku ewidencji',
  enterInventory: 'Wprowadź ewidencję...',
  staticInventoryExample: 'Przykładowa ewidencja statyczna:',
  staticYamlInventoryExample: 'Przykładowa ewidencja statyczna w formacie YAML:',
  keyName: 'Nazwa klucza',
  loginOptional: 'Login (Niewymagany)',
  usernameOptional: 'Nazwa użytkownika (Niewymagana)',
  privateKey: 'Klucz prywatny',
  override: 'Override',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Użyj klucza tego typu dla repozytoriów HTTPS i dla scenariuszy używających połączeń innych niż SSH.',
  deleteKey: 'Usuń klucz',
  newKey: 'Nowy klucz',
  create: 'Utwórz',
  newTask: 'Nowe zadanie',
  cantDeleteThe: 'Nie można usunąć {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} nie może być usunięty ponieważ jest używany przez poniższe zasoby.',
  projectName: 'Nazwa projektu',
  allowAlertsForThisProject: 'Zezwól na powiadomienia dla tego projektu.',
  telegramChatIdOptional: 'Telegram Chat ID (Niewymagany)',
  maxNumberOfParallelTasksOptional: 'Maksimum dla zadań współbieżnych (Niewymagane)',
  deleteRepository: 'Usuń repozytorium',
  newRepository: 'Nowe repozytorium',
  urlOrPath: 'URL lub ścieżka',
  absPath: 'ścieżka bezwgl.',
  branch: 'Gałąź',
  accessKey: 'Klucz dostępu',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Dane dostępowe dla repozytorium Git:',
  ifYouUseGitOrSshUrl: 'jeśli używasz Git-a lub SSH URL.',
  ifYouUseHttpsOrFileUrl: 'jeśli używasz HTTPS lub URL pliku.',
  none: 'None',
  ssh: 'SSH',
  deleteProject: 'Usuń projekt',
  save: 'Zapisz',
  deleteProject2: 'Usuń projekt',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Usunięcia projektu nie można cofnąć. Na pewno chcesz to zrobić?',
  name2: 'Nazwa *',
  title: 'Tytuł *',
  description: 'Opis',
  required: 'Wymagane',
  key: '{expr}',
  surveyVariables: 'Przejrzyj zmienne',
  addVariable: 'Dodaj zmienną',
  columns: 'Kolumny',
  buildVersion: 'Build Version',
  messageOptional: 'Komunikat (nieobowiązkowy)',
  debug: 'Debuguj',
  dryRun: 'Przebieg próbny',
  diff: 'Diff',
  advanced: 'Zaawansowane',
  hide: 'Ukryj',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Zezwól na napisywanie argumentów CLI w ustawieniach Szablonu Zadania',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'CLI Args (JSON array) np. [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Rozpoczęte',
  author: 'Autor',
  duration: 'Czas trwania',
  stop: 'Stop',
  forceStop: 'Wymuś zatrzymanie',
  deleteTeamMember: 'Usuń członka zespołu',
  team2: 'Zespół',
  newTeamMember: 'Nowy członek zespołu',
  user: 'użytkownik',
  administrator: 'Administrator',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definiuje początkową wersję Twojego wytworu. Każde uruchomienie zwiększa wersję.',
  forMoreInformationAboutBuildingSeeThe: 'Aby uzyskać więcej informacji na temat budowania zobacz:',
  taskTemplateReference: 'Odniesienie do szablonu zadania',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Określa który wytwór zostanie wdrożony podczas uruchomienia zadania',
  forMoreInformationAboutDeployingSeeThe: 'Więcej informacji dot. wdrażania znajdziesz',
  taskTemplateReference2: 'Odniesienie do szablonu zadania',
  definesAutorunSchedule: 'Defines autorun schedule.',
  forMoreInformationAboutCronSeeThe: 'Aby uzyskać więcej informacji na temat cron przejrzyj:',
  cronExpressionFormatReference: 'Format wyrażenia CRON',
  startVersion: 'Wersja początkowa',
  example000: 'Np. 0.0.0',
  buildTemplate: 'Stwórz szablon',
  autorun: 'Uruchom automatycznie',
  playbookFilename: 'Plik scenariusza *',
  exampleSiteyml: 'np. site.yml',
  inventory2: 'Ewidencja *',
  repository: 'Repozytorium *',
  environment3: 'Środowisko *',
  vaultPassword: 'Hasło do Vault',
  vaultPassword2: 'Hasło do Vault',
  view: 'Widok',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Chcę uruchomić zadanie z pomocą CRON jedynie dla nowych commit-ów repozytorium',
  repository2: 'Repozytorium',
  cronChecksNewCommitBeforeRun: 'Cron sprawdza nowe commity przed uruchomieniem',
  readThe: 'Przeczytaj ',
  toLearnMoreAboutCron: 'aby dowiedzieć się więcej nt. Cron.',
  suppressSuccessAlerts: 'Wstrzymaj powiadomienia o sukcesie',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'CLI Args (JSON array). Example: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Zezwól na argumenty CLI w zadaniu',
  docs: 'dokumentacja',
  editViews: 'Edytuj widoki',
  newTemplate: 'Nowy szablon',
  taskTemplates2: 'Szablony zadań',
  all: 'Wszystko',
  notLaunched: 'Nie uruchomiono',
  by: 'by {user_name}',
  editTemplate: 'Modyfikuj Szablon',
  newTemplate2: 'Nowy Szablon',
  deleteTemplate: 'Usuń szablon',
  playbook: 'Scenariusz',
  email: 'Email',
  adminUser: 'Administrator',
  sendAlerts: 'Wyślij powiadomienie',
  deleteUser: 'Usuń użytkownika',
  newUser: 'Nowy użytkownik',
  re: 'Odp.{getActionButtonTitle}',
  teamMember: '{expr} Członek Zespołu',
  taskId: 'ID Zadania',
  version: 'Wersja',
  status: 'Status',
  start: 'Start',
  actions: 'Akcje',
  alert: 'Powiadomienie',
  admin: 'Admin',
  role: 'Rola',
  external: 'External',
  time: 'Czas',
  path: 'Ścieżka',
  gitUrl: 'Git URL',
  sshKey: 'Klucz SSH',
  lastTask: 'Ostatnie zadanie',
  task2: 'Zadanie',
  build: 'Buduj',
  deploy: 'Wdrażaj',
  run: 'Uruchom',
  add: 'Dodaj',
  password_required: 'Hasło jest wymagane',
  name_required: 'Nazwa jest wymagana',
  user_credentials_required: 'Dane użytkownika są wymagane',
  type_required: 'Typ jest wymagany',
  path_required: 'Ścieżka do pliku ewidencji jest wymagana',
  private_key_required: 'Klucz prywatny jest wymagany',
  project_name_required: 'Nazwa projektu jest wymagana',
  repository_required: 'Repozytorium jest wymagane',
  branch_required: 'Gałąź (branch) jest wymagana',
  key_required: 'Klucz jest wymagany',
  user_required: 'Użytkownik jest wymagany',
  build_version_required: 'Wersja Build-u jest wymagana',
  title_required: 'Tytuł jest wymagany',
  isRequired: 'jest wymagany/-a',
  mustBeInteger: 'Musi być liczbą całkowitą',
  mustBe0OrGreater: 'Musi być większe lub równe 0 ',
  start_version_required: 'Wersja początkowa jest wymagana',
  playbook_filename_required: 'Plik scenariusza jest wymagany',
  inventory_required: 'Ewidencja jest wymagana',
  environment_required: 'Środowisko jest wymagane',
  email_required: 'Email jest wymagany',
  build_template_required: 'Szablon Build jest wymagany',
  Task: 'Zadanie',
  Build: 'Build',
  Deploy: 'Wdrażaj',
  Run: 'Uruchom',

};
