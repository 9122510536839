var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoaded)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary darken-2"}})],1):_c('div',[_c('v-dialog',{attrs:{"max-width":400,"persistent":"","transition":false},model:{value:(_vm.editViewsDialog),callback:function ($$v) {_vm.editViewsDialog=$$v},expression:"editViewsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('editViews'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeEditViewDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('EditViewsForm',{attrs:{"project-id":_vm.projectId}})],1)],1)],1),_c('EditTemplateDialogue',{attrs:{"project-id":_vm.projectId,"item-app":_vm.itemApp,"item-id":"new"},on:{"save":function($event){return _vm.loadItems()}},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('NewTaskDialog',{attrs:{"project-id":_vm.projectId,"template-id":_vm.itemId,"template-alias":_vm.templateAlias,"template-type":_vm.templateType,"template-app":_vm.templateApp},on:{"save":function($event){_vm.itemId = null},"close":function($event){_vm.itemId = null}},model:{value:(_vm.newTaskDialog),callback:function ($$v) {_vm.newTaskDialog=$$v},expression:"newTaskDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('taskTemplates2'))+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.templateApps.length === 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.can(_vm.USER_PERMISSIONS.manageProjectResources))?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 pr-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.templateApps.length > 0 || _vm.editItem('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('newTemplate'))+" "),(_vm.templateApps.length > 0)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('span',{staticClass:"pl-2"})],1):_vm._e()]}}])},[_c('v-list',_vm._l((_vm.templateApps),function(item){return _c('v-list-item',{key:item,attrs:{"link":""},on:{"click":function($event){_vm.editItem('new'); _vm.itemApp = item;}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? _vm.APP_ICONS[item].darkColor : _vm.APP_ICONS[item].color}},[_vm._v(" "+_vm._s(_vm.APP_ICONS[item].icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.APP_TITLE[item]))])],1)}),1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.settingsSheet = true}}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1),_c('v-tabs',{staticClass:"pl-4",attrs:{"show-arrows":""},model:{value:(_vm.viewTab),callback:function ($$v) {_vm.viewTab=$$v},expression:"viewTab"}},[_c('v-tab',{attrs:{"to":_vm.getViewUrl(null),"disabled":_vm.viewItemsLoading}},[_vm._v(_vm._s(_vm.$t('all')))]),_vm._l((_vm.views),function(view){return _c('v-tab',{key:view.id,attrs:{"to":_vm.getViewUrl(view.id),"disabled":_vm.viewItemsLoading}},[_vm._v(_vm._s(view.title)+" ")])}),(_vm.can(_vm.USER_PERMISSIONS.manageProjectResources))?_c('v-btn',{staticClass:"mt-2 ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.editViewsDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],2),_c('v-data-table',{staticClass:"mt-4 templates-table",style:({
        opacity: _vm.viewItemsLoading ? 0.3 : 1,
      }),attrs:{"hide-default-footer":"","single-expand":"","show-expand":"","headers":_vm.filteredHeaders,"items":_vm.items,"items-per-page":Number.MAX_VALUE,"expanded":_vm.openedItems},on:{"update:expanded":function($event){_vm.openedItems=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [(_vm.templateApps.length > 0)?_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.APP_ICONS[item.app].icon)+" ")]):_vm._e(),_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.TEMPLATE_TYPE_ICONS[item.type])+" ")]),_c('router-link',{attrs:{"to":_vm.viewId
            ? ("/project/" + _vm.projectId + "/views/" + _vm.viewId + "/templates/" + (item.id))
            : ("/project/" + _vm.projectId + "/templates/" + (item.id))}},[_vm._v(_vm._s(item.name)+" ")])]}},{key:"item.version",fn:function(ref){
            var item = ref.item;
return [(item.last_task && item.last_task.tpl_type !== '')?_c('TaskLink',{attrs:{"disabled":true,"status":item.last_task.status,"task-id":item.last_task.tpl_type === 'build'
            ? item.last_task.id
            : (item.last_task.build_task || {}).id,"label":item.last_task.tpl_type === 'build'
            ? item.last_task.version
            : (item.last_task.build_task || {}).version,"tooltip":item.last_task.tpl_type === 'build'
            ? item.last_task.message
            : (item.last_task.build_task || {}).message}}):_c('div',[_vm._v("—")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.last_task != null)?_c('div',{staticClass:"mt-2 mb-2 d-flex"},[_c('TaskStatus',{attrs:{"status":item.last_task.status}})],1):_c('div',{staticClass:"mt-3 mb-2 d-flex",staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.$t('notLaunched')))])]}},{key:"item.last_task",fn:function(ref){
            var item = ref.item;
return [(item.last_task != null)?_c('div',{staticClass:"mt-2 mb-2",staticStyle:{"line-height":"1"}},[_c('TaskLink',{attrs:{"task-id":item.last_task.id,"label":'#' + item.last_task.id,"tooltip":item.last_task.message}}),_c('div',{staticStyle:{"color":"gray","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('by', {user_name: item.last_task.user_name}))+" ")])],1):_vm._e()]}},{key:"item.inventory_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.inventory.find(function (x) { return x.id === item.inventory_id; }) || {name: '—'}).name)+" ")]}},{key:"item.environment_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.environment.find(function (x) { return x.id === item.environment_id; }) || {name: '—'}).name)+" ")]}},{key:"item.repository_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.repositories.find(function (x) { return x.id === item.repository_id; }).name)+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"pl-1 pr-2",attrs:{"text":""},on:{"click":function($event){return _vm.createTask(item.id)}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-play")]),_vm._v(" "+_vm._s(_vm.TEMPLATE_TYPE_ACTION_TITLES[item.type])+" ")],1)]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [(_vm.openedItems.some(function (template) { return template.id === item.id; }))?_c('td',{attrs:{"colspan":headers.length}},[_c('TaskList',{staticStyle:{"border":"1px solid lightgray","border-radius":"6px","margin":"10px 0"},attrs:{"template":item,"limit":5,"hide-footer":true}})],1):_vm._e()]}}])}),_c('TableSettingsSheet',{attrs:{"table-name":"project__template","headers":_vm.headers},on:{"change":_vm.onTableSettingsChange},model:{value:(_vm.settingsSheet),callback:function ($$v) {_vm.settingsSheet=$$v},expression:"settingsSheet"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }