export default {
  backup: '備份',
  downloadTheProjectBackupFile: '下載專案備份檔（json格式）',
  restoreProject: '恢复...',
  incorrectUsrPwd: '使用者名稱或密碼錯誤',
  askDeleteUser: '您確定要刪除此使用者嗎？ ',
  askDeleteTemp: '您確實要刪除此範本嗎？ ',
  askDeleteEnv: '您確實要刪除此環境嗎？ ',
  askDeleteInv: '您確實要刪除此主機配置嗎？ ',
  askDeleteKey: '您確定要刪除此金鑰嗎？ ',
  askDeleteRepo: '您確定要刪除此儲存庫嗎？ ',
  askDeleteProj: '您確定要刪除此項目嗎？ ',
  askDeleteTMem: '您確定要刪除此團隊成員嗎？ ',
  edit: '編輯',
  nnew: '新建',
  keyFormSshKey: 'SSH 金鑰',
  keyFormLoginPassword: '使用密碼登入',
  keyFormNone: '無',
  incorrectUrl: 'URL位址不正確',
  username: '使用者名稱',
  username_required: '未填入使用者名稱',
  dashboard: '控制台',
  history: '歷史',
  activity: '活動',
  settings: '設定',
  signIn: '登入',
  password: '密碼',
  changePassword: '更改密碼',
  editUser: '編譯使用者',
  newProject: '新建專案',
  close: '關閉',
  newProject2: '新建專案...',
  demoMode: 'DEMO MODE',
  task: '任務 #{expr}',
  youCanRunAnyTasks: '您可以執行任何任務',
  youHaveReadonlyAccess: '您只有唯讀存取權限',
  taskTemplates: '任務範本',
  inventory: '主機配置',
  environment: '環境',
  keyStore: '金鑰庫',
  repositories: '儲存庫',
  darkMode: '暗色模式',
  team: '團隊',
  users: '使用者',
  editAccount: '帳戶編輯',
  signOut: '註銷',
  error: '錯誤',
  refreshPage: '刷新頁面',
  relogin: '重新登入',
  howToFixSigninIssues: '如何解決登入問題',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: '首先，您需要登入執行 Semaphore 的伺服器。 ',
  executeTheFollowingCommandOnTheServerToSeeExisting: '在伺服器上執行以下命令查看現有使用者：',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: '您可以更改現有使用者的密碼：',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: '或建立新的管理員使用者：',
  close2: '關閉',
  semaphore: 'SEMAPHORE',
  dontHaveAccountOrCantSignIn: '沒有帳戶或無法登入？ ',
  password2: '密碼',
  cancel: '關閉',
  noViews: '沒有分組視圖',
  addView: '新增分組檢視',
  editEnvironment: '編輯環境',
  deleteEnvironment: '刪除環境',
  environment2: '環境',
  newEnvironment: '新增環境',
  environmentName: '環境名稱',
  extraVariables: '擴展變數',
  enterExtraVariablesJson: '新增額外的Json格式變數...',
  environmentVariables: '環境變數',
  enterEnvJson: '新增額外的Json格式環境變數...',
  environmentAndExtraVariablesMustBeValidJsonExample: '環境變數和額外變數必須是有效的 JSON。 例如：',
  dashboard2: '控制台',
  ansibleSemaphore: 'Ansible Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: '抱歉，如果未啟用 JavaScript，<%= htmlWebpackPlugin.options.title %> 將無法正常運作。 請啟用它以繼續。 ',
  deleteInventory: '刪除主機配置',
  newInventory: '新增主機配置',
  name: '名稱',
  userCredentials: '使用者憑證',
  sudoCredentialsOptional: 'Sudo 憑證（可選）',
  type: '類型',
  pathToInventoryFile: '主機設定檔路徑',
  enterInventory: '編輯主機配置...',
  staticInventoryExample: '靜態主機配置範例:',
  staticYamlInventoryExample: '靜態 YAML 格式主機設定範例:',
  keyName: '憑證名稱',
  loginOptional: '登入名稱 (可選)',
  usernameOptional: '使用者名稱 (可選)',
  privateKey: '私鑰',
  override: '覆蓋',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: '對於 HTTPS 儲存庫和使用非 SSH 連線的 playbook，請使用此類金鑰。 ',
  deleteKey: '刪除憑證',
  newKey: '新增憑證',
  create: '創建',
  newTask: '建立任務',
  cantDeleteThe: '無法刪除 {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '無法刪除 {objectTitle}，因為它已被如下資源使用',
  projectName: '專案名稱',
  allowAlertsForThisProject: '在此專案開啟通知',
  telegramChatIdOptional: 'Telegram Chat ID (可選)',
  maxNumberOfParallelTasksOptional: '最大並行任務數 (可選)',
  deleteRepository: '刪除儲存庫',
  newRepository: '新增儲存庫',
  urlOrPath: 'URL 或路徑',
  absPath: 'abs. path',
  branch: '分支',
  accessKey: '存取憑證',
  credentialsToAccessToTheGitRepositoryItShouldBe: '存取 Git 儲存庫的憑證。 它應該是:',
  ifYouUseGitOrSshUrl: '如果您使用 Git 或 SSH URL.',
  ifYouUseHttpsOrFileUrl: '如果您使用 HTTPS 或檔案 URL.',
  none: 'None',
  ssh: 'SSH',
  deleteProject: '刪除項目',
  save: '儲存',
  deleteProject2: '刪除項目',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: '一旦刪除項目，將無法恢復 ！',
  name2: '名稱 *',
  title: '標題 *',
  description: '說明',
  required: '必填',
  key: '{expr}',
  surveyVariables: '列出變數',
  addVariable: '新增變數',
  columns: '列',
  buildVersion: '編譯版本',
  messageOptional: '說明 (可選)',
  debug: '調試模式',
  dryRun: 'Dry Run',
  diff: '顯示差異',
  advanced: '進階選項',
  hide: '隱藏',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: '請在設定中開啟 「允許在任務中自訂 CLI 參數」',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'CLI 測試 (JSON 陣列). 例如: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: '已啟動',
  author: '關聯用戶',
  duration: '說明',
  stop: '停止',
  forceStop: '強制刪除',
  deleteTeamMember: '刪除團隊成員',
  team2: '團隊',
  newTeamMember: '新增團隊成員',
  user: '使用者',
  administrator: '管理員',
  definesStartVersionOfYourArtifactEachRunIncrements: '定義起始版本，每次運行都會增加版本。 ',
  forMoreInformationAboutBuildingSeeThe: '有關構建的更多信息，請參閱',
  taskTemplateReference: '任務範本參考',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: '定義任務執行時應部署哪些產物。 ',
  forMoreInformationAboutDeployingSeeThe: '有關部署的更多信息，請參閱',
  taskTemplateReference2: '任務範本參考',
  definesAutorunSchedule: '定義計畫任務',
  forMoreInformationAboutCronSeeThe: '有關 cron 的更多信息，請參閱',
  cronExpressionFormatReference: 'Cron 表達式格式參考',
  startVersion: '開始版本',
  example000: '例如: 0.0.0',
  buildTemplate: '建置範本',
  autorun: '自動運行',
  playbookFilename: 'Playbook 檔案名稱 *',
  exampleSiteyml: '例如: site.yml',
  inventory2: '主機配置 *',
  repository: '儲存庫 *',
  environment3: '環境 *',
  vaultPassword: 'Vault 密碼',
  vaultPassword2: 'Vault 密碼',
  view: '查看',
  cron: '計劃任務',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: '我想透過 cron 執行一個任務，只用於某些儲存庫的新提交',
  repository2: '儲存庫',
  cronChecksNewCommitBeforeRun: 'Cron 在執行前檢查新的提交',
  readThe: '閱讀',
  toLearnMoreAboutCron: '了解更多關於 Cron 的資訊。 ',
  suppressSuccessAlerts: 'Suppress success alerts',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'CLI 參數 (JSON 陣列格式). 例如: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: '允許任務中自訂 CLI 參數',
  docs: '文檔',
  editViews: '編輯檢視',
  newTemplate: '新增模板',
  taskTemplates2: '任務範本',
  all: '全部',
  notLaunched: '未啟動',
  by: 'by {user_name}',
  editTemplate: '編輯範本',
  newTemplate2: '新建範本',
  deleteTemplate: '刪除模板',
  playbook: 'Playbook',
  email: '信箱',
  adminUser: '管理員使用者',
  sendAlerts: '發送通知',
  deleteUser: '刪除使用者',
  newUser: '新增使用者',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Team Member',
  taskId: '任務 ID',
  version: '版本',
  status: '狀態',
  start: '啟動',
  actions: '任務',
  alert: '通知',
  admin: '管理員',
  role: '角色',
  external: '擴展',
  time: '時間',
  path: '路徑',
  gitUrl: 'Git 儲存庫連結',
  sshKey: 'SSH 密鑰',
  lastTask: '最後一次任務',
  task2: '任務',
  build: '編譯',
  deploy: '部署',
  run: '執行',
  add: '新增',
  password_required: '密碼是必填項',
  name_required: '名稱是必填項',
  user_credentials_required: '使用者憑證是必填項',
  type_required: '型別是必填項',
  path_required: '主機設定檔路徑是必填項',
  private_key_required: '私鑰是是必填項',
  project_name_required: '專案名稱是必填項',
  repository_required: '儲存庫位址是必填項',
  branch_required: '分支是必填項',
  key_required: 'Key 是必填項',
  user_required: '使用者是必填項',
  build_version_required: '編譯版是必填項',
  title_required: '標題是必填項',
  isRequired: '是必填項',
  mustBeInteger: '必須是整數',
  mustBe0OrGreater: '必須大於或等於 0',
  start_version_required: '開始版本是必填項',
  playbook_filename_required: 'Playbook 檔案名稱是必填項',
  inventory_required: '主機配置是必填項',
  environment_required: '環境配置是必填項',
  email_required: '信箱是必填項',
  build_template_required: '編譯模板是必填項',
  Task: '任務',
  Build: '編譯',
  Deploy: '部署',
  Run: '運行',
  CreateDemoProject: '建立範本專案',
  LeaveProject: '離開專案',

};
